import axios from 'axios';
import { useMainStore } from '../store/index';

const api = axios.create({
  baseURL: process.env.VUE_APP_DS_API_URL,
});

const getFromStore = (prop) => {
  const store = useMainStore();
  return store[prop];
};

api.interceptors.request.use((config) => {
  config.headers.Authorization = getFromStore('token');
  return config;
}, (error) => {
  return Promise.reject(error);
});

const getConfigurationV2 = async (offerId = getFromStore('offerId')) => api.get(`/v2/offers/${offerId}/configuration`);
const getImages = async (specificationImpacts, offerId = getFromStore('offerId'), market = getFromStore('market')) => api.post(`/v1/markets/${market}/offers/${offerId}/images`, specificationImpacts);
const getSpecification = async (offerId = getFromStore('offerId'), market = getFromStore('market')) => api.get(`/v1/markets/${market}/offers/${offerId}/specification`);
const getFpcCodeTranslations = async (fpcCodes, locale) => {
  // The API only handles 100 codes per request
  let requests = [];
  for (let i = 0; i < fpcCodes?.length; i += 100) {
    requests.push(
      api.post('/v1/fpcCodes/translations', { fpcCodes: fpcCodes.slice(i, i + 100), locale }),
    );
  }

  const responses = await Promise.all(requests);
  const results = responses.reduce((list, response) => [...list, ...response.data], []);
  return Promise.resolve({ data: results });
};
const getOfferIds = async (market = getFromStore('market')) => api.get(`/v1/markets/${market}/user/offers/ids`);
const finalizeOffer = async (data, offerId = getFromStore('offerId'), market = getFromStore('market')) => api.post(`/v1/markets/${market}/offers/${offerId}/finalize`, data);
const approveRegulations = async (offerId = getFromStore('offerId')) => api.put(`/v1/offers/${offerId}/approve/regulations`);
const getOfferStatus = async (offerId = getFromStore('offerId')) => api.get(`/v1/offers/${offerId}/status`);
const getTranslations = async (locale) => api.get(`/v1/translations/${locale}`);
const requestSupport = async (phone, email, offerId = getFromStore('offerId'), market = getFromStore('market')) => api.post(`/v1/markets/${market}/offers/${offerId}/support`, { email, phone });
const updateSelections = async (selections, offerId = getFromStore('offerId')) => api.post(`/v1/offers/${offerId}/selections`, selections);
const getUserMetadata = async (federationUserAccountId) => api.get(`/v1/users/${federationUserAccountId}/metadata`);
const removeUserResetPasswordMapping = async (federationUserAccountId) => api.delete(`/v1/users/${federationUserAccountId}/resetpasswordmapping`);
const addTrackingEvent = async (evetData, tid) => api.put(`/v1/tracking-events/${tid}/event`, evetData);
const addTrackingEventByOffer = async (evetData, offerId = getFromStore('offerId')) => api.put(`/v1/offers/${offerId}/tracking-events`, evetData);
const addOfferComment = async (offerComment, offerId = getFromStore('offerId'), market = getFromStore('market')) => api.post(`/v1/markets/${market}/offers/${offerId}/comments`, offerComment);
const getOfferComments = async (store = useMainStore()) => api.get(`/v1/offers/comments`, { params: { offerId: getFromStore('offerId'), ...(!store.isSalesman ? { federationUserAccountId: store.federationUserAccountId } : {}) } });
const getSalesmanOfferings = async () => api.get(`/v1/offerings-salesman`);
const checkFeatureToggle = async (featureKey, market = getFromStore('market')) => api.get(`/v1/markets/${market}/feature-toggle/${featureKey}/check`);
const getAppConfiguration = async (configKey, market = getFromStore('market')) => api.get(`/v1/markets/${market}/app-configuration/${configKey}`);
const getMarketTranslations = async (market, locale) => api.get(`/v1/markets/${market}/translations/${locale}`);

export {
  getConfigurationV2,
  getImages,
  getSpecification,
  getFpcCodeTranslations,
  getOfferIds,
  getOfferStatus,
  finalizeOffer,
  getTranslations,
  requestSupport,
  approveRegulations,
  updateSelections,
  getUserMetadata,
  removeUserResetPasswordMapping,
  addTrackingEvent,
  addTrackingEventByOffer,
  addOfferComment,
  getOfferComments,
  getSalesmanOfferings,
  checkFeatureToggle,
  getAppConfiguration,
  getMarketTranslations,
};
