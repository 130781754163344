<script setup>
import { computed, onMounted } from 'vue';
import PowertrainSection from '@/components/configuratorSections/PowertrainSection';
import CabColorSection from '@/components/configuratorSections/CabColorSection';
// import StylingAndComfortSection from '@/components/configuratorSections/StylingAndComfortSection';
import StylingAndComfortSectionV2 from '@/components/configuratorSections/StylingAndComfortSectionV2';
// import ChassisSection from '@/components/configuratorSections/ChassisSection';
import ChassisSectionV2 from '@/components/configuratorSections/ChassisSectionV2';
import FuelEfficiencySection from '@/components/configuratorSections/FuelEfficiencySection';
import ConnectedServicesSection from '@/components/configuratorSections/ConnectedServicesSection';
import SafetySectionGroup from '@/components/configuratorSections/SafetySectionGroup';
import RepairMaintenanceSection from '@/components/configuratorSections/RepairMaintenanceSection';

import { useMainStore } from '@/store/index.js';
const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

const store = useMainStore();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  offerTemplate: {
    type: String,
    default: 'PL_STANDARDA4X2EB',
    required: true,
  },
  specType: {
    type: String,
    default: '',
    required: true,
  },
  isFrameAgreement: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const emit = defineEmits(['select']);

onMounted(() => {
  //set custom sections
  store.sections = [
      { id: 'powertrain', name: 'drivelineHeaderTitle' },
      { id: 'cab-color', name: 'cabColourHeaderTitle' },
      { id: 'styling-comfort', name: 'stylingComfortHeaderTitle' },
      { id: 'chassis', name: 'chassisHeaderTitle' },
      { id: 'safety', name: 'safetyHeaderTitle' },
      { id: 'fuel-efficiency', name: 'fuelEfficiencyHeaderTitle' },
      { id: 'connectedServices', name: 'connectedServicesHeaderTitle' },
      { id: 'repairMaintenance', name: 'repairMaintenanceHeaderTitle' },
    ];
});

const options = computed(() => {
  return store.options;
});

const images = computed(() => {
  return store.images;
});

const interiorImages = computed(() => {
  return { interior: images.value.interior, dashboard: images.value.dashboard };
});

const imageChassisSection = computed(() => {
  return (store.sectionMetadata?.chassis?.descriptionImage) ? `${staticImagesBaseUrl}/${store.sectionMetadata?.chassis?.descriptionImage}` : null;
});
</script>
<template>
  <PowertrainSection
    :items="options.driveline"
    @select="emit('select', $event, 'driveline')"
  />
  <CabColorSection
    :items="options.cabColour"
    :front-image="images?.front"
    @select="emit('select', $event, 'cabColour')"
  />

  <StylingAndComfortSectionV2
    :items="options.stylingComfort"
    :images="interiorImages"
    :offer-template="offerTemplate"
    :spec-type="specType"
    :is-frame-agreement="isFrameAgreement"
    @select="emit('select', $event, 'stylingComfort')"
  />
  <!-- <ChassisSection
    :items="options.chassis"
    :images="{ side: imageChassisSection, isSide: images?.side }"
    class="chasis-section"
    @select="emit('select', $event, 'chassis')"
  /> -->
  <ChassisSectionV2
    :items="options.chassis"
    :images="{ side: imageChassisSection, isSide: images?.side }"
    :offer-template="offerTemplate"
    :spec-type="specType"
    :is-frame-agreement="isFrameAgreement"
    class="chasis-section"
    @select="emit('select', $event, 'chassis')"
  />
  <SafetySectionGroup
    :items="options.safety"
    @select="emit('select', $event, 'safety')"
  />
  <FuelEfficiencySection
    :items="options.fuelEfficiency"
    :images="{ view: images?.view }"
    @select="emit('select', $event, 'fuelEfficiency')"
  />
  <ConnectedServicesSection
    :skip-ecolution-validation="true"
    :items="options.connectedServices"
    @select="emit('select', $event, 'connectedServices')"
  />

  <RepairMaintenanceSection
    :items="options.repairMaintenance"
    @select="emit('select', $event, 'repairMaintenance')"
  />
</template>
  
<style scoped>
.chasis-section >>> .configurator-section__image > img {
  max-width: 70%;
}

@media only screen and (max-width: 1294px) {
  .chasis-section >>> .configurator-section__image > img {
      max-width: 80%;
    }
}

@media only screen and (max-width: 1000px) {
  .chasis-section >>> .configurator-section__image > img {
      max-width: 90%;
    }
}

@media only screen and (max-width: 600px) {
  .chasis-section >>> .configurator-section__image > img {
    max-width: 120%;
  }
}

</style>

