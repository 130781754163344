<script setup>
import { inject, ref, onMounted, computed, watch } from 'vue';
import SddsDropdown from '../../components/SddsDropdown.vue';
import VehicleCard from '../../components/VehicleCard.vue';
import PageSpinner from '../../components/PageSpinner.vue';
import ChipFilters from '../../components/ChipFilters.vue';
import { getConfigurationV2, getImages, addTrackingEvent, getSalesmanOfferings } from '../../services/digitalSalesService';
import { useMainStore } from '../../store/index.js';

const adobeEvent = inject('adobeEvent');

const store = useMainStore();

const availableVehicleSelectionFilters = [
  { id: 'stockVehicle', name: 'availableOffersStockVehiclesTitle' },
  { id: 'tailorMade', name: 'availableOffersTailorMadeVehiclesTitle' },
  { id: 'frameAgreement', name: 'availableOffersFrameAgreementTitle' },
];

const availableVehicleTypeFilters = [
  { id: 'sCab', name: 'availableOffersSVehicle' },
  { id: 'rCab', name: 'availableOffersRVehicle' },
  { id: 'ebSpec', name: 'availableOffersExtraLowChassis' },
  { id: 'naSpec', name: 'availableOffersNormalHeightChassis' },
];

const availableOffers = ref([]);
const isLoading = ref(false);
const showError = ref(false);
const availableSalesmanOfferings = ref([]);
const selectedOffering = ref(null);
const selectedOfferingVatNip = ref(null);
const isOfferingSelectionLoading = ref(false);
const showYearEndOfferInline = ref(false);
const showYear2024OfferInline = ref(false);
const vehicleSelectionFilters = ref([]);
const vehicleTypeFilters = ref([]);
const selectedVehicleSelectionFilters = ref([]);
const selectedVehicleTypeFilters = ref([]);
const offersConfigurations = ref([]);
const showEcolutionBlob = ref(false);

const toggleVehicleSelectionFilters = (vehicleSelectionFilter) => {
  if (selectedVehicleSelectionFilters.value.includes(vehicleSelectionFilter)) selectedVehicleSelectionFilters.value = selectedVehicleSelectionFilters.value.filter(v => v !== vehicleSelectionFilter);
  else selectedVehicleSelectionFilters.value.push(vehicleSelectionFilter);
  adobeEvent({
    event: 'vehicleSelectionFilter',
    filterInfo: {
      filterName: vehicleSelectionFilter,
      filterAction: selectedVehicleSelectionFilters.value.includes(vehicleSelectionFilter) ? 'remove' : 'add',
    },
  });
  localStorage.setItem('selectedVehicleSelectionFilters', JSON.stringify(selectedVehicleSelectionFilters.value));
};

const toggleVehicleTypeFilters = (vehicleTypeFilter) => {
  if (selectedVehicleTypeFilters.value.includes(vehicleTypeFilter)) selectedVehicleTypeFilters.value = selectedVehicleTypeFilters.value.filter(v => v !== vehicleTypeFilter);
  else selectedVehicleTypeFilters.value.push(vehicleTypeFilter);
  adobeEvent({
    event: 'vehicleTypeFilter',
    filterInfo: {
      filterName: vehicleTypeFilter,
      filterAction: selectedVehicleTypeFilters.value.includes(vehicleTypeFilter) ? 'remove' : 'add',
    },
  });
  localStorage.setItem('selectedVehicleTypeFilters', JSON.stringify(selectedVehicleTypeFilters.value));
};

const sendTracking = () => {
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'AvailableOffersView',
    },
  });
  if (store.tid) {
    const data = {
      event: 'availableOffersView',
      data: { timestamp: (new Date()).toISOString() },
    };
    addTrackingEvent(data, store.tid).then(() => {
      store.tid = null;
    }).catch(err => {
      console.error('failed to create tracking event', err);
    }).finally(() => {
      console.log('finish create event load');
    });
  }
};

const sortCreatedDate = (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
const sortSortOrder = (a, b) => Number(b.sortOrder || null) - Number(a.sortOrder || null);

const fetchOfferConfigurationImages = async () => {
  let offers = [];
  if (store.isAdmin || store.isSuperUser) {
    offers = await Promise.all(store.activeOffers.map(async item => await (await getConfigurationV2(item.offerId)).data));
  } else if (store.isSalesman) {
    offers = await Promise.all(selectedOffering.value?.offerings[0].offers.map(async offerId => await (await getConfigurationV2(offerId)).data) ?? []);
  } else {
    offers = await Promise.all(store.offers.map(async item => await (await getConfigurationV2(item.offerId)).data));
  }
  if (offers.length) offers.sort(sortCreatedDate).sort(sortSortOrder);
  offersConfigurations.value = offers;
  const vehicleSelectionFiltersKeys = [];
  const vehicleTypeFiltersKeys = [];
  availableOffers.value = await Promise.all(offers.map(async ({ offerId, options, misc, expired, finalized, isOfferNotValid = false, createdDate, sortOrder }, index) => {
    const getOfferValidDate = () => {
      if (!misc?.offerValidUntilDate) return [];
      const date = new Date(misc?.offerValidUntilDate);
      return [`${String(date.getDate()).padStart(2, '0')}.${(String(date.getMonth()+1)).padStart(2, '0')}.${String(date.getFullYear()).substring(2)}`];
    };
    const checkIfTemplateEB = misc?.template && misc?.template === 'PL_STANDARDA4X2EB';
    const checkIfStockSpec = () => misc?.specType && misc?.specType === 'stock_vehicle' ? 'StockSpec' : '';
    const checkIfSCab = () => misc?.cabType && misc?.cabType === 's_cab' ? 'SCab' : '';
    const checkIfFrameAgreement = () => misc?.frameAgreement ? 'Dsv' : '';
    // set dynamic filters keys
    if (!(checkIfStockSpec())) vehicleSelectionFiltersKeys.push('tailorMade');
    if (!!(checkIfStockSpec()) && !misc?.frameAgreement) vehicleSelectionFiltersKeys.push('stockVehicle');
    if (misc?.frameAgreement) vehicleSelectionFiltersKeys.push('frameAgreement');
    if (!!(checkIfSCab())) vehicleTypeFiltersKeys.push('sCab');
    if (!(checkIfSCab())) vehicleTypeFiltersKeys.push('rCab');
    if (checkIfTemplateEB) vehicleTypeFiltersKeys.push('ebSpec');
    if (!checkIfTemplateEB) vehicleTypeFiltersKeys.push('naSpec');
    return {
      offerId,
      createdDate,
      sortOrder,
      title: (misc?.vehicleCardHighlightsHeader) || (checkIfTemplateEB ? `offer${checkIfFrameAgreement()}${checkIfStockSpec()}ExtraLowChassisHeader${checkIfSCab()}` : `offer${checkIfFrameAgreement()}${checkIfStockSpec()}NormalChassisHeader${checkIfSCab()}`),
      imageUrl: 'https://cdn.digitaldesign.scania.com/icons/website_hosted/truck.svg',
      additionalInfo: misc?.vehicleCardAdditionalInfo || null,
      keyPoints: [
        { icon: 'tick', translationKey: (misc?.vehicleCardHighlightsText1) || (checkIfTemplateEB ? `offer${checkIfFrameAgreement()}${checkIfStockSpec()}ExtraLowChassisInformation1${checkIfSCab()}` : `offer${checkIfFrameAgreement()}${checkIfStockSpec()}NormalChassisInformation1${checkIfSCab()}`) },
        { icon: 'tick', translationKey: (misc?.vehicleCardHighlightsText2) || (checkIfTemplateEB ? `offer${checkIfFrameAgreement()}${checkIfStockSpec()}ExtraLowChassisInformation2${checkIfSCab()}` : `offer${checkIfFrameAgreement()}${checkIfStockSpec()}NormalChassisInformation2${checkIfSCab()}`) },
        { icon: 'tick', translationKey: (misc?.vehicleCardHighlightsText3) || (checkIfTemplateEB ? `offer${checkIfFrameAgreement()}${checkIfStockSpec()}ExtraLowChassisInformation3${checkIfSCab()}` : `offer${checkIfFrameAgreement()}${checkIfStockSpec()}NormalChassisInformation3${checkIfSCab()}`) },
      ],
      offerStatus: { expired, finalized, isOfferNotValid },
      offerData: { offerValidDate: getOfferValidDate() },
      misc,
      tailorMade: !(checkIfStockSpec()),
      stockVehicle: !!(checkIfStockSpec()),
      frameAgreement: misc?.frameAgreement,
      sCab: !!(checkIfSCab()),
      rCab: !(checkIfSCab()),
      ebSpec: checkIfTemplateEB,
      naSpec: !checkIfTemplateEB,
    };
  }));
 // set dynamic filters
  const uniqueVehicleSelectionFiltersKeys = [...new Set(vehicleSelectionFiltersKeys)];
  const uniqueVehicleTypeFiltersKeys = [...new Set(vehicleTypeFiltersKeys)];
  availableVehicleSelectionFilters.forEach(f => uniqueVehicleSelectionFiltersKeys.includes(f.id) && vehicleSelectionFilters.value.push(f));
  availableVehicleTypeFilters.forEach(f => uniqueVehicleTypeFiltersKeys.includes(f.id) && vehicleTypeFilters.value.push(f));
};

const fetchSalesmanOfferings = async () => {
  const response = await getSalesmanOfferings().catch(err => {
    console.error('error fetching salesman offerings :: ', err);
    showError.value = true;
  });
  if (!showError.value) {
    availableSalesmanOfferings.value = Object.values(response.data);
    selectedOffering.value = availableSalesmanOfferings.value[0];
    selectedOfferingVatNip.value = availableSalesmanOfferings.value[0]?.vatNip;
  }
};

const setFeatureToggle = async () => {
  store.showYearEndOfferBanner = false;
  store.showYear2024OfferBanner = false;
  if (availableOffers.value?.length > 0) {
    const checkIfFrameAgreementExist = !!availableOffers.value.filter(aO => aO.frameAgreement).length;
    const checkIfStockVehicleExist = !!availableOffers.value.filter(aO => aO.stockVehicle && !aO.frameAgreement).length;
    const checkIfTailorMadeVehicleExist = !!availableOffers.value.filter(aO => aO.tailorMade && !aO.frameAgreement).length;
    const checkIfOnlyStockVehicleExist = checkIfStockVehicleExist && !checkIfFrameAgreementExist && !checkIfTailorMadeVehicleExist;
    if (checkIfStockVehicleExist) {
      const response = store.featureToggleData[`${store.market}#webapp_yearEndOfferBanner`];
      if (response) {
        const { isActive, isEffectiveUser, isFeatureDateValid } = response;
        const showYearEndOffer = isActive && isEffectiveUser && isFeatureDateValid;
        if (checkIfOnlyStockVehicleExist) store.showYearEndOfferBanner = showYearEndOffer;
        else showYearEndOfferInline.value = showYearEndOffer;
      }
      const response2 = store.featureToggleData[`${store.market}#webapp_year2024OfferBanner`];
      if (response2) {
        const { isActive } = response2;
        const showYear2024Offer = isActive;
        if (checkIfOnlyStockVehicleExist) store.showYear2024OfferBanner = showYear2024Offer;
        else showYear2024OfferInline.value = showYear2024Offer;
      }
    }
    if (checkIfTailorMadeVehicleExist || checkIfStockVehicleExist) {
      const response3 = store.featureToggleData[`${store.market}#webapp_show_ecolutionBlob`];
      if (response3) showEcolutionBlob.value = response3?.isActive;
    }
  }
};

const setFilters = () => {
  selectedVehicleSelectionFilters.value = JSON.parse(localStorage.getItem('selectedVehicleSelectionFilters') || '[]');
  selectedVehicleTypeFilters.value = JSON.parse(localStorage.getItem('selectedVehicleTypeFilters') || '[]');
};

const handleOfferingSelection = async (offeringVatNip) => {
  isOfferingSelectionLoading.value = true;
  selectedOfferingVatNip.value = offeringVatNip;
  selectedOffering.value = availableSalesmanOfferings.value.find(aSO => aSO.vatNip === offeringVatNip);
  await fetchOfferConfigurationImages();
  isOfferingSelectionLoading.value = false;
};

const stockVehicleFilter = (o) => o.stockVehicle && !o.frameAgreement;
const frameAgreementFilter = (o) => o.frameAgreement;
const tailorMadeFilter = (o) => o.tailorMade && !o.frameAgreement;
const checkSelectedVehicleTypeFilters = (o) => {
  let returnFlag = false;
  if (selectedVehicleTypeFilters.value.length < 1) returnFlag = true;
  else selectedVehicleTypeFilters.value.forEach((sVTF, i) => {
    if (i === 0 && o[sVTF]) returnFlag = true;
    else returnFlag = returnFlag && o[sVTF];
  });
  return returnFlag;
};
const stockVehiclesOffers = computed(() => availableOffers.value.filter(stockVehicleFilter).filter(checkSelectedVehicleTypeFilters).sort(sortCreatedDate).sort(sortSortOrder));
const frameAgreementOffers = computed(() => availableOffers.value.filter(frameAgreementFilter).filter(checkSelectedVehicleTypeFilters).sort(sortCreatedDate).sort(sortSortOrder));
const tailorMadeVehiclesOffers = computed(() => availableOffers.value.filter(tailorMadeFilter).filter(checkSelectedVehicleTypeFilters).sort(sortCreatedDate).sort(sortSortOrder));

const organizedAvailableOffers = computed(() => {
  const returnArray = [];
  if (stockVehiclesOffers.value.length && (selectedVehicleSelectionFilters.value.length < 1 || selectedVehicleSelectionFilters.value.includes('stockVehicle'))) returnArray.push({
    id: 1, title: 'availableOffersStockVehiclesTitle', text: 'availableOffersStockVehiclesText',
    offers: stockVehiclesOffers.value, cardHeaderChips: { specialOffer: showYearEndOfferInline.value || showYear2024OfferInline.value, ecolution: showEcolutionBlob.value },
  });
  if (frameAgreementOffers.value.length && (selectedVehicleSelectionFilters.value.length < 1 || selectedVehicleSelectionFilters.value.includes('frameAgreement'))) returnArray.push({
    id: 2, title: 'availableOffersFrameAgreementTitle', text: 'availableOffersFrameAgreementText',
    offers: frameAgreementOffers.value, cardHeaderChips: {},
  });
  if (tailorMadeVehiclesOffers.value.length && (selectedVehicleSelectionFilters.value.length < 1 || selectedVehicleSelectionFilters.value.includes('tailorMade'))) returnArray.push({
    id: 3, title: 'availableOffersTailorMadeVehiclesTitle', text: 'availableOffersTailorMadeVehiclesText',
    offers: tailorMadeVehiclesOffers.value, cardHeaderChips: { ecolution: showEcolutionBlob.value },
  });
  return returnArray;
});

onMounted(async () => {
  isLoading.value = true;
  sendTracking();
  if (store.isSalesman) await fetchSalesmanOfferings();
  await fetchOfferConfigurationImages();
  await setFeatureToggle();
  setFilters();
  isLoading.value = false;
});

watch(availableOffers, async () => {
  const getSpecImpactGrouped = (selections) => Object.entries(selections).map(([groupKey, group]) => {
    if (['leasing', 'payment', 'driverServices', 'connectedServices'].includes(groupKey)) return [];
    return Object.entries(group).map(([key, selection]) => ({ key, specImpact: selection.specImpact }));
  }).flat(1);

  offersConfigurations.value.forEach(async offerObj => {
    const selected = {};
    const optionKeys = Object.keys(offerObj.options);
    optionKeys.forEach(key => {
      offerObj.options[key].forEach(item => {
        const defaultVal = item.options.find(x => x.default);
        if (!selected[key]) selected[key] = {};
        selected[key][item.id] = defaultVal || null;
      });
    });
    const imagesRes = await getImages(getSpecImpactGrouped(selected), offerObj.offerId, store.market).catch(e => console.error('Error for getImages :: ', e));
    const tempOffer = availableOffers.value?.find(o => o.offerId === offerObj.offerId);
    if (tempOffer) tempOffer.imageUrl = imagesRes?.data?.view || 'https://cdn.digitaldesign.scania.com/icons/website_hosted/truck.svg';
  });
});

</script>
<template>
  <template v-if="isLoading">
    <PageSpinner />
  </template>
  <template v-else>
    <template v-if="availableOffers.length < 1">
      <div class="no-active-offers">
        <h1>{{ $i18n('noActiveOffers') }}</h1>
        <p>{{ $i18n('noActiveOffersText') }}</p>
      </div>
    </template>
    <template v-else>
      <div class="active-offers__container">
        <div class="active-offers__header sdds-u-relative">
          <div>
            <p class="sdds-paragraph-01">
              {{ $i18n('availableOffersHeroTitle') }}
            </p>
            <p class="sdds-expressive-headline-01">
              {{ $i18n('availableOffersHeroHeader') }}
            </p>
            <p class="sdds-paragraph-02">
              {{ $i18n('availableOffersHeroBody') }}
            </p>
          </div>
          <template v-if="showEcolutionBlob && !frameAgreementOffers.length">
            <div class="ecolution-blob">
              <h2 class="sdds-u-m0">
                {{ $i18n('availableOffersOfferTitle') }}
              </h2>
              <p class="sdds-u-m0">
                {{ $i18n('availableOffersOfferDescription') }}
              </p>
            </div>
          </template>
        </div>
        <div
          class="active-offers__main-content"
          :class="{'is-there-blob': !frameAgreementOffers.length}"
        >
          <template v-if="store.isSalesman">
            <div>
              <p class="sdds-headline-01">
                {{ $i18n('availableSalesmanOffersBodyHeading') }}
              </p>
              <p class="sdds-paragraph-02">
                {{ $i18n('availableSalesmanOffersBodyText') }}
              </p>
            </div>
            <div v-if="availableSalesmanOfferings">
              <div class="salesman-offerings">
                <div class="sdds-row">
                  <div class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-12 sdds-col-lg-4 sdds-u-pl0">
                    <label
                      for="rolesDropdown"
                      class="sdds-headline-02"
                    >
                      {{ $i18n('availableSalesmanOffersTitleCustomer') }}
                    </label>
                    <SddsDropdown
                      id="offeringDropdown"
                      :selection="selectedOfferingVatNip"
                      :options="availableSalesmanOfferings"
                      value-key="vatNip"
                      option-key="companyName"
                      class="sdds-u-pt2"
                      @update:selection="handleOfferingSelection"
                    />
                  </div>
                  <div
                    v-if="selectedOffering"
                    class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-12 sdds-col-lg-8 sdds-u-pr0"
                  >
                    <div class="company-information">
                      <div class="sdds-row">
                        <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                          {{ $i18n('availableSalesmanOffersTitleCompany') }}
                        </div>
                        <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                          {{ selectedOffering.companyName }}
                        </div>
                      </div>
                      <div class="sdds-row sdds-u-pt2">
                        <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                          {{ $i18n('availableSalesmanOffersTitleContactName') }}
                        </div>
                        <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                          {{ selectedOffering.offerings[0].customerName }}
                        </div>
                      </div>
                      <div class="sdds-row sdds-u-pt2">
                        <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                          {{ $i18n('availableSalesmanOffersTitleContactEmail') }}
                        </div>
                        <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                          {{ selectedOffering.offerings[0].customerEmail }}
                        </div>
                      </div>
                      <div class="sdds-row sdds-u-pt2">
                        <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                          {{ $i18n('availableSalesmanOffersTitleVat') }}
                        </div>
                        <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                          {{ selectedOffering.vatNip }}
                        </div>
                      </div>
                      <div class="sdds-row sdds-u-pt2">
                        <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                          {{ $i18n('availableSalesmanOffersTitleClassification') }}
                        </div>
                        <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                          {{ selectedOffering.customerClassification }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="isOfferingSelectionLoading">
            <div class="spinner fa-4x">
              <font-awesome-icon
                icon="spinner"
                class="fa-spin"
              />
            </div>
          </template>
          <template v-else>
            <div v-if="vehicleSelectionFilters.length > 1 || vehicleTypeFilters.length > 1">
              <p class="sdds-headline-02">
                {{ $i18n('availableOffersFilter') }}
              </p>
              <template v-if="vehicleSelectionFilters.length > 1">
                <p class="sdds-paragraph-02">
                  {{ $i18n('availableOffersVehicleSelection') }}
                </p>
                <ChipFilters
                  :options="vehicleSelectionFilters"
                  :selected-options="selectedVehicleSelectionFilters"
                  id-field="id"
                  name-field="name"
                  @click="toggleVehicleSelectionFilters"
                />
              </template>
              <template v-if="vehicleTypeFilters.length > 1">
                <p class="sdds-paragraph-02">
                  {{ $i18n('availableOffersVehicleType') }}
                </p>
                <ChipFilters
                  :options="vehicleTypeFilters"
                  :selected-options="selectedVehicleTypeFilters"
                  id-field="id"
                  name-field="name"
                  @click="toggleVehicleTypeFilters"
                />
              </template>
            </div>
            <template v-if="organizedAvailableOffers.length > 0">
              <template
                v-for="{id, title, text, offers, cardHeaderChips} in organizedAvailableOffers"
                :key="id"
              >
                <div v-if="offers.length">
                  <div>
                    <p class="sdds-headline-02 sdds-u-mb0">
                      {{ $i18n(title) }}
                    </p>
                    <p class="sdds-paragraph-03 sdds-u-mb0">
                      {{ $i18n(text) }}
                    </p>
                  </div>
                  <div class="active-offers__card-list sdds-u-mt0 sdds-u-mb3">
                    <VehicleCard
                      v-for="card in offers"
                      :key="card.offerId"
                      :offer-id="card.offerId"
                      :title="card.title"
                      :keypoints="card.keyPoints"
                      :image-url="card.imageUrl"
                      :offer-status="card.offerStatus"
                      :offer-data="card.offerData"
                      :card-header-chips="cardHeaderChips"
                      :additional-info="card.additionalInfo"
                    />
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="no-filtered-offers">
                <h1>{{ $i18n('noFilteredOffers') }}</h1>
                <p>{{ $i18n('noFilteredOffersText') }}</p>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </template>
</template>

<style scoped>
.sdds-expressive-headline-01 {
  margin: unset;
  line-height: 1.2;
}
.active-offers__header > div > .sdds-paragraph-01 {
  margin: 0;
  padding: 0;
}
.active-offers__card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--sdds-spacing-element-32);
  width: 100%;
  margin-top: var(--sdds-spacing-element-16);
}
.active-offers__card-list > * {
  width: calc(33% - var(--sdds-spacing-element-32));
}
.active-offers__container {
  display: flex;
  flex-direction: column;
}
.active-offers__header {
  background-image: url('@/assets/active-offers-bg.jpeg');
  background-repeat: no-repeat;
  background-position: 60% 60%;
  background-size: cover;
  min-height: 40vh;
  color: white;
}
.active-offers__header > div {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-48);
}
.ecolution-blob {
  position: absolute;
  display: inline-block;
  right: 150px;
  bottom: -130px;
  width: 228px !important;
  height: 228px !important;
  border-radius: 50%;
  border: 2px solid #C84A07F2;
  background: linear-gradient(0deg, rgba(200, 74, 7, 0.95), rgba(200, 74, 7, 0.95)), linear-gradient(0deg, rgba(217, 86, 16, 0.95), rgba(217, 86, 16, 0.95));
  text-align: center;
  padding-top: 65px;
}
.ecolution-blob p {
    line-height: 1.3em;
  }
.active-offers__main-content {
  display: flex;
  flex-direction: column;
  margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-48);
}
.active-offers__main-content > div:first-child {
  width: 60%;
}
.salesman-offerings {
  background: var(--sdds-grey-100);
  margin: 0px -48px;
  padding: 24px 48px 48px;
}
.company-information {
  padding-top: 44px;
}
.spinner {
  margin-top: 16px;
  height: inherit;
}
.no-active-offers, .no-filtered-offers {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media only screen and (max-width: 1366px) {
  .active-offers__card-list {
    margin-top: var(--sdds-spacing-element-16) !important;
  }
  .active-offers__card-list > * {
    width: 47%;
    margin: 0;
  }
  .active-offers__main-content > div:first-child {
    width: 100%;
  }
}
@media only screen and (max-width: 1294px) {
  .ecolution-blob {
    right: 0px;
  }
  .is-there-blob {
    margin-top: var(--sdds-spacing-layout-72) !important;
  }
}
@media only screen and (max-width: 1024px) {
  .active-offers__card-list > * {
    margin: unset;
  }
}
@media only screen and (max-width: 768px) {
  .sdds-paragraph-01 {
    font-size: 5rem;
  }
  .sdds-expressive-headline-01 {
    font-size: 12rem;
  }
  .active-offers__header > div {
    width: 80%;
    margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-16);

  }
  .active-offers__main-content {
    margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-16);
  }
  .salesman-offerings {
    margin: 0px 0px;
  }
  .ecolution-blob {
    width: 160px !important;
    height: 160px !important;
    bottom: -100px;
    padding-top: 2.7em;
  }
  .ecolution-blob h2 {
    font-size: 1.9em;
  }
  .ecolution-blob p {
    font-size: 0.9em;
  }
  .active-offers__card-list > * {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 650px) {
  .ecolution-blob {
    bottom: -140px;
  }
}
</style>
